import { TreeLeaf, TreeNode, RteNode } from '~/types/rte/rteTypes'

// Method to check if a json RTE field is empty
export const isEmptyJsonRteField = (json: RteNode) => {
  return !json.children.some((child: TreeNode) => {
    return child.children.some((child) => {
      const text = (child as TreeLeaf)?.text
      // If we have text prop, and text is not empty, return true
      if (text) return text !== ''
      return false
    })
  })
}

export const hasDocEmptyTexts = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!hasDocEmptyTexts(obj[key])) {
          return false
        }
      } else if (key === 'text' && obj[key] !== '') {
        return false
      }
    }
  }
  return true
}
