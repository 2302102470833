















































import { computed, defineComponent } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/fp/isEmpty'

import { MappedHeroBanner } from '~/types/cms/homepage'
import { stripHTMLTags } from '@/common/utils/characters'

import Marquee from '@/components/global/Marquee.vue'

// Types
import { HomepageRowsHeroBand } from '~/modules/contentstack/types/generated/types'

// Content page parsers
import { parseImageConnectionItem } from '@/modules/contentstack/parser/contentPageParser'

export default defineComponent({
  components: { Marquee },
  props: {
    section: {
      type: Object as () => HomepageRowsHeroBand,
      required: true,
    },
  },
  setup(props) {
    const heroBand = props.section.hero_band

    const data = {
      headline: heroBand?.headline,
      bottomTickerHeadlineText: heroBand?.bottom_ticker_headline_text,
      tickerApplications: heroBand?.ticker_applications,
      heroSubheader: heroBand?.subheader,
      richTextHeadline: heroBand?.rich_text_headline
        ? stripHTMLTags(heroBand.rich_text_headline)
        : heroBand?.rich_text_headline,
      richTextSubheader: heroBand?.rich_text_subheader,
      backgroundImage: heroBand?.background_imageConnection
        ? parseImageConnectionItem(heroBand.background_imageConnection)
        : null,
    } as MappedHeroBanner

    // Optional hero overlay class
    // Dynamic classes defined in Tailwind safelist
    const heroOverlayClass = computed(() => {
      if (!heroBand?.overlay || heroBand.overlay === 'none') {
        return
      }

      return `tw-from-${heroBand.overlay}/${heroBand.overlay_transparency}`
    })

    // Hero ribbon styles
    // Dynamic classes defined in Tailwind safelist
    const heroRibbonStyles = computed(() => {
      return `tw-bg-${heroBand?.bottom_ticker_background}/${heroBand?.bottom_ticker_background_transparency}`
    })

    return {
      isEmpty,
      data,
      heroOverlayClass,
      heroRibbonStyles,
    }
  },
})
