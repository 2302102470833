








import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import { defineComponent, useContext, useAsync } from '@nuxtjs/composition-api'

// Components
import HomepageRowsHeroBand from '@/components/feature/content/homepage/HeroBanner.vue'
import HomepageRowsVideoBand from '@/components/feature/content/homepage/HomepageVideo.vue'
import HomepageRowsTiles from '@/components/feature/content/homepage/HomepageRowTiles.vue'
import HomepageRowsTilesWithLinks from '@/components/feature/content/homepage/HomepageTilesWithLinks.vue'

// Stores
import { useCMSGlobalSettingsStore } from '~/store'
import { TLD } from '~/common/constants/utils'
import { useCMSMeta } from '~/composables/cms/useCMSMeta'
import { useCanonicalUrl } from '@/composables/useBaseUrl'

export default defineComponent({
  name: 'Homepage',
  components: {
    HomepageRowsHeroBand,
    HomepageRowsVideoBand,
    HomepageRowsTilesWithLinks,
    HomepageRowsTiles,
  },
  layout: 'homepage',
  setup() {
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const ctx = useContext()
    const canonicalUrl = useCanonicalUrl()

    // Fetch data
    const data = useAsync(async () => {
      try {
        const request = (await ctx.$cms.api.fetchHomepage({
          locale: 'en-us',
          fallbackLocale: true,
        })) as any

        // If no data is returned, redirect to 404
        if (!request.data?.all_homepage?.items?.at(0)) {
          ctx.redirect('/not-found')
        }

        return request.data?.all_homepage?.items?.at(0)
      } catch (error: unknown) {
        ctx.redirect('/not-found')
      }
    })

    // Set the meta tags for title and description and ld+json scripts
    useCMSMeta(data.value?.seo_metadata, () => {
      let host

      if (process.server) {
        host = process.static ? '' : detectServerBaseUrl(ctx.req)
      }
      if (process.client) {
        host = location.origin
      }

      const hostExtension = findTLD(host)?.toLowerCase()
      let technicalSupportPhone = '+1-978-867-2388'
      let mediaUrl = ctx.$config.cst.mediaUrl

      if (hostExtension === TLD.CN.toLowerCase()) {
        technicalSupportPhone = '4006-473287'
        mediaUrl = ctx.$config.cst.cn.mediaUrl
        //
      } else if (hostExtension === TLD.JP.toLowerCase()) {
        technicalSupportPhone = '03 (3295) 1632'
      }

      // SEO Metadata
      const CMSMetaData = data.value.seo_metadata.map((item: any) => {
        return {
          ...{
            hid: item.type,
            name: item.type,
            content: item.value,
          },
        }
      })

      return {
        title: data.value.seo_metadata.filter((item: any) => item.type === 'title')[0].value,
        link: [
          {
            rel: 'image_src',
            type: 'image/png',
            href: mediaUrl + '/www/images/global/cst-social-default-global.png',
          },
          {
            media: 'all',
            rel: 'canonical',
            href: canonicalUrl,
          },
          {
            rel: 'alternate',
            hreflang: 'en-US',
            href: ctx.$config.cst.domainDefault,
          },
          {
            rel: 'alternate',
            hreflang: 'zh-CN',
            href: ctx.$config.cst.domainChina,
          },
          { rel: 'alternate', hreflang: 'ja-JP', href: ctx.$config.cst.domainJapan },
          { rel: 'alternate', hreflang: 'en-JP', href: ctx.$config.cst.domainDefault },
          { rel: 'alternate', hreflang: 'x-default', href: ctx.$config.cst.domainDefault },
        ],
        meta: [
          ...CMSMetaData,
          {
            httpEquiv: 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            property: 'cst:page-title-analytics',
            content: 'Cell Signaling Technology (CST): Antibodies, Reagents, Proteomics, Kits and Consumables',
            class: 'OneLinkNoTx',
          },
          {
            name: 'msvalidate.01',
            content: '6E6B75594B9368D45BABCB439321C9B2',
          },
          { name: 'google-site-verification', content: 'qHBWEqzEmQR6wpQneO7wQF02vQzoeScap3ieBs6Nos0' },
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'Corporation',
              name: 'Cell Signaling Technology',
              alternateName: ['CST', 'Cell Signaling'],
              description:
                "Cell Signaling Technology (CST) is a private, family-owned company, founded by scientists and dedicated to providing the world's highest quality, innovative research and diagnostic products to accelerate biological understanding and enable personalized medicine.",
              founder: {
                '@type': 'Person',
                name: 'Michael J. Comb',
                honorificPrefix: 'Dr',
                image: `${mediaUrl}/www/images/about/leadership/michael-comb.png`,
                url: `${host}/contents/about-us/our-company/leadership-team`,
                sameAs: 'https://www.linkedin.com/in/michael-comb-86928010',
              },
              url: host,
              logo:
                ctx.$config.cst.cloudflare.images.deliveryUrl +
                '/' +
                getAttributeByCountry('headerBrandLogo').image.cloudflareId +
                '/1X',
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: technicalSupportPhone,
                contactType: 'technical support',
              },
              sameAs: [
                'https://www.facebook.com/cellsignal',
                'https://twitter.com/cellsignal',
                'https://www.instagram.com/cell_signaling_technology/',
                'https://www.linkedin.com/company/cell-signaling-technology/',
                'https://www.youtube.com/user/cellsignaldotcom',
                'https://en.wikipedia.org/wiki/Cell_Signaling_Technology',
                'https://www.wikidata.org/wiki/Q3392342',
                'https://www.bloomberg.com/profile/company/3340494Z:US',
                'https://www.crunchbase.com/organization/cell-signaling-technology',
              ],
            },
          },
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: host,
              potentialAction: {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: `https://www.cellsignal.com/browse?itemtype=product&status=Released&search={search_term_string}`,
                },
                'query-input': 'required name=search_term_string',
              },
            },
          } as any,
        ],
      }
    })

    return {
      data,
    }
  },

  head: {},
})
