
















import { defineComponent, Ref, ref, useContext } from '@nuxtjs/composition-api'
import { ContentImage } from '~/types/cms/image'

export default defineComponent({
  components: {
    // Asynchronous import because of circular references.
    // More info: https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Circular-References-Between-Components
    JsonRteField: () => import('~/components/global/cms/JsonRteField.vue'),
  },
  props: {
    data: {
      required: true,
      type: Object as () => ContentImage,
    },
  },
  setup(props) {
    const isLoaded: Ref<boolean> = ref(false)
    const ctx = useContext()

    const onImgLoad = () => {
      isLoaded.value = true
    }

    const getAlt = () => {
      if (props.data.alt_text) {
        return props.data.alt_text
      }
      return ''
    }

    const getUrl = () => {
      if (props.data.media_server_id && props.data.media_server_id.length > 0) {
        return props.data.media_server_id.includes('https://')
          ? props.data.media_server_id
          : ctx.$img(`${props.data.media_server_id}`, null, { provider: 'ms' })
      }
      if (props.data.cloudflare_id && props.data.cloudflare_id.length > 0) {
        return props.data.cloudflare_id.includes('https://')
          ? props.data.cloudflare_id
          : ctx.$img(props.data.cloudflare_id, null, { provider: 'mc' }) + '/2X'
      }
    }

    return { onImgLoad, getUrl, getAlt, isLoaded }
  },
})
