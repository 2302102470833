export const CUSTOM_BLOCKS = {
  BUTTON: 'cstButton',
  LAYOUT: 'cstLayout',
  ZOOM_MODAL: 'cstZoomModal',
  REFERENCE: 'reference',
  ALPHA_OL: 'cstAlphaOl',
  LIST_ITEM: 'cstListItem',
} as const

export const CUSTOM_TEXTS = {
  ABBR: 'cstAbbrTitle',
  CITE: 'cstCite',
  CUSTOM_RED_TEXT: 'cstRedText',
  CUSTOM_HIGHLIGHT_TEXT: 'cstHighlightColor',
  ITALIC: 'italic',
} as const

export const BLOCK_LEVEL_ELEMENTS = [
  ...Object.values(CUSTOM_BLOCKS),
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'div',
  'p',
  'reference',
  'img',
]

export const ASSET_TYPES = {
  IMAGE: {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    GIF: 'image/gif',
    SVG: 'image/svg+xml',
    WEBP: 'image/webp',
    TIFF: 'image/tiff',
    BMP: 'image/bmp',
    ICON: 'image/x-icon',
  },
  AUDIO: {
    MPEG: 'audio/mpeg',
    WAV: 'audio/wav',
    OGG: 'audio/ogg',
    AAC: 'audio/aac',
    WEBM: 'audio/webm',
    FLAC: 'audio/flac',
    MIDI: 'audio/x-midi',
  },
  VIDEO: {
    MP4: 'video/mp4',
    WEBM: 'video/webm',
    OGG: 'video/ogg',
    MPEG: 'video/mpeg',
    QUICKTIME: 'video/quicktime',
    MATROSKA: 'video/x-matroska',
  },
  APPLICATION: {
    JSON: 'application/json',
    XML: 'application/xml',
    PDF: 'application/pdf',
    JAVASCRIPT: 'application/javascript',
    ZIP: 'application/zip',
    FORM_URLENCODED: 'application/x-www-form-urlencoded',
  },
  TEXT: {
    PLAIN: 'text/plain',
    HTML: 'text/html',
    CSS: 'text/css',
    JAVASCRIPT: 'text/javascript',
    CSV: 'text/csv',
    MARKDOWN: 'text/markdown',
  },
} as const
