import { Page, PageContent } from '@/types/cms/page'
import { Page as CMSPage, PageContent as CMSPageContent } from '~/modules/contentstack/types'

function parseKey(str: string) {
  if (str.charAt(0) === '_' || str === 'link_v2') return str

  return str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
}

// Parse all object keys to convert snake_case to camelCase
const parseAllObjectKeys = (obj: Record<string, any>) => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      parseAllObjectKeys(obj[i])
    }
  } else if (typeof obj === 'object') {
    for (const key in obj) {
      const newKey = parseKey(key)
      obj[newKey] = obj[key]
      if (key !== newKey) {
        delete obj[key]
      }
      parseAllObjectKeys(obj[newKey])
    }
  }

  return obj
}

// Recursive method to parse the component fields
function parseComponent(fields: Record<string, any>): PageContent | null {
  if (fields === null) return null

  // Create new object with the parsed fields
  const newFields = fields

  // loop over keys
  for (const key of Object.keys(newFields)) {
    if (newFields[key] === undefined) return null

    // Create new object with parsed data
    const value = newFields[key]

    // Parse edges & nodes - flatten structure and rename key
    // Also convert key to camelCase
    if (value?.edges) {
      newFields[key.replace('connection', '')] = value.edges.map((edge: any) => edge.node)[0]
      delete newFields[key]
    }
    // If no edges but object or array, check again
    // Recursion
    else if (Array.isArray(value) || typeof value === 'object') {
      newFields[key] = parseComponent(value)
    }
  }

  return newFields as PageContent
}

// Parse the template content
export function parsePage(page: CMSPage): Page | null {
  if (!page || !page.content?.length) return null

  // Get content
  const content = page.content as CMSPageContent[]

  // Loop over content objects and change them
  const parsedContent = content.map((item) => {
    const { __typename } = item

    const type = __typename
    const parsedItem = parseAllObjectKeys(item)
    const fields = parseComponent(parsedItem) ?? null

    return {
      type,
      fields,
    }
  })

  const parsedSEOMetadata = page.seo_metadata?.map((item) => {
    return {
      type: item?.type,
      value: item?.value,
      entity: item?.entityConnection?.edges?.[0]?.node?.cloudflare_id,
    }
  })

  // Get the page title
  const title: string = page.title ?? ''

  // Return object to be used in the store
  return {
    title,
    content: parsedContent,
    seoMetadata: parsedSEOMetadata,
  } as Page
}
