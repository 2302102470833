
































import { defineComponent } from '@nuxtjs/composition-api'
import { useCountry } from '~/composables/country/useCountry'

// Components
import ContentImage from '@/components/feature/static-content/components/ContentImage.vue'

// Content page parsers
import {
  parseImageConnectionItem,
  parseNavigationLinkConnectionItem,
} from '@/modules/contentstack/parser/contentPageParser'

// Types
import { HomepageRowsTilesWithLinks } from '~/modules/contentstack/types/generated/types'
import { MappedHomepageRowsTilesWithLinks, MappedNewLink } from '~/types/cms/homepage'

export default defineComponent({
  name: 'HomepageTilesWithLinks',
  components: {
    ContentImage,
  },
  props: {
    section: {
      type: Object as () => HomepageRowsTilesWithLinks,
      required: true,
    },
  },
  setup(props) {
    const { isCN } = useCountry()
    const showLink = (link: MappedNewLink) => {
      // If China, show all
      if (isCN.value) return true

      // For all other countries, show if this calue is false
      if (!link.show_on_cn_domain_only) return true

      return false
    }

    const getLayoutClass = () => {
      return props?.section?.tiles_with_links?.second_row_tile_layout ?? ''
    }

    // Parse data
    const tiles = props.section?.tiles_with_links?.tile_with_links?.map((tile: any) => {
      if (!tile.tile_image) {
        tile.tile_image = parseImageConnectionItem(tile.tile_imageConnection)
      }

      tile.new_links = tile.links.map((link: any) => {
        return {
          ...parseNavigationLinkConnectionItem(link.linkConnection),
          show_on_cn_domain_only: link.show_on_cn_domain_only,
          icon_class_name: link.icon.class_name,
        }
      }) as MappedHomepageRowsTilesWithLinks

      return tile
    })

    return { getLayoutClass, showLink, tiles }
  },
})
